<template>
  <HAWPageContent :breadcrumb="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseCard class="p-3">
        <BaseRow>
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4">{{ $t("prf.title") }}</BaseHeadline>
          </BaseCol>
          <BaseCol cols="12">
            <ProfileDataForm />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import HAWPageContent from "../../../haw-components-hac/Base/HAWPageContent";
import ProfileDataForm from "./partials/ProfileDataForm";

export default {
  name: "Profile",

  components: {
    HAWPageContent,
    ProfileDataForm,
  },
};
</script>

<style lang="scss" scoped>
.card--fill-space {
  height: 100%;
}
</style>
