<template>
  <BaseForm v-if="!showProfile.isLoading.value" :validation="v$" ref="form">
    <BaseRow>
      <BaseCol cols="3" class="pr-3">
        <BaseTextField
          v-model="formValues.salutation"
          label="prf.update.form.label.salutation"
          :validation="v$.salutation"
          :messages="bvShowErrors('salutation').messages"
          :error="bvShowErrors('salutation').hasMessages"
          :maxLength="maxLength.USERS.SALUTATION"
          floatingLabel
        />
      </BaseCol>

      <BaseCol v-if="formValues.teacher" cols="3">
        <BaseTextField
          v-model="formValues.title"
          label="prf.update.form.label.title"
          :validation="v$.title"
          :messages="bvShowErrors('title').messages"
          :error="bvShowErrors('title').hasMessages"
          :maxLength="maxLength.USERS.TITLE"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>

    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          v-model="formValues.firstName"
          label="prf.update.form.label.firstName"
          :validation="v$.firstName"
          :messages="bvShowErrors('firstName').messages"
          :error="bvShowErrors('firstName').hasMessages"
          :maxLength="maxLength.USERS.FIRST_NAME"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>

    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          v-model="formValues.lastName"
          label="prf.update.form.label.lastName"
          :validation="v$.lastName"
          :messages="bvShowErrors('lastName').messages"
          :error="bvShowErrors('lastName').hasMessages"
          :maxLength="maxLength.USERS.LAST_NAME"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>

    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          v-model="formValues.street"
          label="prf.update.form.label.street"
          :validation="v$.street"
          :messages="bvShowErrors('street').messages"
          :error="bvShowErrors('street').hasMessages"
          :maxLength="maxLength.USERS.STREET"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>

    <BaseRow>
      <BaseCol cols="3">
        <BaseTextField
          v-model="formValues.zipcode"
          label="prf.update.form.label.zipcode"
          :validation="v$.zipcode"
          :messages="bvShowErrors('zipcode').messages"
          :error="bvShowErrors('zipcode').hasMessages"
          :maxLength="maxLength.USERS.ZIPCODE"
          floatingLabel
        />
      </BaseCol>

      <BaseCol cols="9">
        <BaseTextField
          v-model="formValues.city"
          label="prf.update.form.label.city"
          :validation="v$.city"
          :messages="bvShowErrors('city').messages"
          :error="bvShowErrors('city').hasMessages"
          :maxLength="maxLength.USERS.CITY"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>

    <BaseRow>
      <BaseCol cols="12">
        <CountrySelect
          v-model="formValues.country"
          :validation="v$.country"
          :messages="bvShowErrors('country').messages"
          :error="bvShowErrors('country').hasMessages"
        />
      </BaseCol>
    </BaseRow>

    <BaseRow>
      <BaseCol cols="6">
        <BaseTextField
          v-model="formValues.phone"
          label="prf.update.form.label.phone"
          :validation="v$.phone"
          :messages="bvShowErrors('phone').messages"
          :error="bvShowErrors('phone').hasMessages"
          :maxLength="maxLength.USERS.PHONE"
          floatingLabel
        />
      </BaseCol>

      <BaseCol cols="6">
        <BaseTextField
          v-model="formValues.mobile"
          label="prf.update.form.label.mobile"
          :validation="v$.mobile"
          :messages="bvShowErrors('mobile').messages"
          :error="bvShowErrors('mobile').hasMessages"
          :maxLength="maxLength.USERS.MOBILE"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>

    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          v-model="formValues.email"
          label="prf.update.form.label.email"
          floatingLabel
          disabled
          :maxLength="null"
        />
      </BaseCol>
    </BaseRow>

    <BaseRow v-if="formValues.teacher && formValues.teacher.teacherApplication">
      <BaseCol cols="12">
        <BaseTextArea
          v-model="formValues.teacher.teacherApplication.shortApplication"
          label="prf.update.form.label.shortApplication"
          placeholder="prf.update.form.placeholder.shortApplication"
          :validation="v$.shortApplication"
          :messages="bvShowErrors('shortApplication').messages"
          :error="bvShowErrors('shortApplication').hasMessages"
          :maxLength="null"
          floatingLabel
          disabled
          rows="10"
        />
      </BaseCol>
    </BaseRow>

    <BaseRow>
      <BaseCol cols="12">
        <BaseMessage :closable="false">
          <p class="mx-3 font-bold">
            {{ $t("prf.password.change.title") }}
          </p>

          <p class="mx-3 mt-2">
            {{ $t("prf.password.change.content") }}
          </p>
        </BaseMessage>
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseRow class="align-content-center justify-content-end">
        <BaseCol class="flex flex-grow-0">
          <BaseDeleteData
            update-module="usm"
            icon="pi pi-trash"
            update-method="deleteProfile"
            deleteDialogMessage="prf.delete.dialog.message"
            deleteDialogHeader="prf.delete.dialog.header"
            outlined
            :onDeleted="logout"
          />
        </BaseCol>

        <BaseCol class="flex flex-grow-0">
          <BaseButton label="prf.update.form.label.submit" @click="submit" :loading="isLoading" />
        </BaseCol>
      </BaseRow>
    </template>
  </BaseForm>
  <div v-else class="text-center pb-5">
    <BaseProgressSpinner />
  </div>
</template>

<script>
import useApi from "@use/useApi";
import useFormValidation from "@use/useFormValidation";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import useBackendValidation from "@use/useBackendValidation";
import { reactive, watch } from "vue";
import useProfile from "@use/useProfile";
import UseNotifications from "@use/useNotifications";
import CountrySelect from "@/modules/haw-components-hac/Base/CountrySelect";
import maxLength from "@/modules/haw-components-hac/constants/maxLength";

export default {
  name: "ProfileDataForm",
  components: { CountrySelect },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { showSuccess } = UseNotifications();

    const form = reactive({});

    const rules = {
      salutation: ["required"],
      firstName: ["required"],
      lastName: ["required"],
      street: ["required"],
      zipcode: ["required"],
      city: ["required"],
      country: ["required"],
      phone: ["required"],
    };

    const { adjustedFormValues } = useProfile();

    const showProfile = useApi({
      module: "usm",
      method: "showProfile",
      onMount: true,
      onSuccess: () => {
        form.salutation = showProfile.docs.value.data.salutation;
        form.title = showProfile.docs.value.data.title;
        form.firstName = showProfile.docs.value.data.firstName;
        form.lastName = showProfile.docs.value.data.lastName;
        form.email = showProfile.docs.value.data.email;
        form.teacher = showProfile.docs.value.data.teacher;

        if (showProfile.docs.value.data.userAddress) {
          form.street = showProfile.docs.value.data.userAddress.street;
          form.city = showProfile.docs.value.data.userAddress.city;
          form.country = showProfile.docs.value.data.userAddress.country;
          form.zipcode = showProfile.docs.value.data.userAddress.zipcode;
        }

        if (showProfile.docs.value.data.userContact) {
          form.phone = showProfile.docs.value.data.userContact.phone;
          form.mobile = showProfile.docs.value.data.userContact.mobile;
        }
      },
    });

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const { callApi, backendValidation, isLoading } = useApi({
      module: "usm",
      method: "updateProfile",
      onSuccess: () => {
        showSuccess(
          "prf.update.notification.success.summary",
          "prf.update.notification.success.detail"
        );
      },
    });

    const { bvShowErrors, updateValidation } = useBackendValidation();
    watch(backendValidation, () => updateValidation(backendValidation.value));

    const submit = () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      callApi(formValues);
    };

    const logout = () => {
      store.dispatch("usm/auth/removeToken");
      router.push("/");
    };

    return {
      v$,
      formValues,
      adjustedFormValues,
      callApi,
      isLoading,
      submit,
      logout,
      bvShowErrors,
      showProfile,
      maxLength,
    };
  },
};
</script>
