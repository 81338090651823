import { reactive, computed } from "vue";
import useApi from "@use/useApi";
import nullifyObject from "../helper/nullifyObject";

/**
 * Use profile
 *
 * @method
 * @returns {object} { profile }
 */
const useProfile = () => {
  // Initialize form values
  const profileAddress = reactive({
    street: null,
    city: null,
    country: null,
    zipcode: null,
  });

  const profileContact = reactive({
    mobile: null,
    phone: null,
  });

  const profileForm = reactive({
    salutation: null,
    title: null,
    firstName: null,
    lastName: null,
    password: null,
    // eslint-disable-next-line camelcase
    password_confirmation: null,
    profileAddress,
    profileContact,
    teacher: null,
  });

  const adjustedFormValues = computed(() => {
    const formValuesCopy = Object.assign({}, profileForm);

    formValuesCopy.profileAddress = nullifyObject(profileAddress);
    formValuesCopy.profileContact = nullifyObject(profileContact);

    return formValuesCopy;
  });

  const { docs, hasDocs, callApi } = useApi({
    module: "usm",
    method: "showProfile",
    onMount: true,
    onSuccess: () => {
      profileForm.salutation = docs.value.data.salutation;
      profileForm.title = docs.value.data.title;
      profileForm.firstName = docs.value.data.firstName;
      profileForm.lastName = docs.value.data.lastName;
      profileForm.email = docs.value.data.email;
      profileForm.teacher = docs.value.data.teacher;
      if (docs.value.data.userAddress) {
        profileAddress.street = docs.value.data.userAddress.street;
        profileAddress.city = docs.value.data.userAddress.city;
        profileAddress.country = docs.value.data.userAddress.country;
        profileAddress.zipcode = docs.value.data.userAddress.zipcode;
      }
      if (docs.value.data.userContact) {
        profileContact.phone = docs.value.data.userContact.phone;
        profileContact.mobile = docs.value.data.userContact.mobile;
      }
    },
  });

  return { hasDocs, callApi, profileAddress, profileContact, profileForm, adjustedFormValues };
};

export default useProfile;
